<template>
  <component :is="patientModel === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="patientModel === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="patientModel" pills>
    

      <!-- Tab: Information -->
      <b-tab active style="pointer-events: none;" >
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('socialhistory')}}</span>
        </template>
        <medical-history  :patientModel="patientModel" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </component>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleGender from "@/store/settings/gender/moduleGender.js";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";
import medicalHistory from "@/views/patientProfile/medicalHistory.vue";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryMedications:[],
          PatientMedicalHistoryPastSurgeries:[]
        },
      },
      trueFlag: true,
      falseFlag: false,
    };
  },
  components: {
    BAlert, BButton, BCard, BLink, BTab, BTabs,medicalHistory,
    Datepicker,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    this.$store.dispatch("GenderList/GetAllGenders");
    debugger;
    var PatientID = this.$route.params.ID;
    this.$store
      .dispatch("patientList/GetPatientMedicalHoistory", PatientID)
      .then((res) => {
        if (res.status == 200) {
          debugger;
          this.patientModel = res.data.Data.Patient;
          this.patientModel.PatientMedicalHistory = res.data.Data;
          if (
          this.patientModel.PatientMedicalHistory == null ||
          this.patientModel.PatientMedicalHistory == undefined
        ) {
          this.patientModel.PatientMedicalHistory = {};
        }
        else{
          debugger
        if(this.patientModel.PatientMedicalHistory.IsLowPressure==true){
            this.patientModel.PatientMedicalHistory.IsHavePresssure = undefined;
          }
          if(this.patientModel.NumOfChildren>0){
               this.patientModel.HaveChildren =  true;
             }
        }
          // if(this.patientModel.Patient){
          // this.patientModel.Patient.PatientMedicalHistory = res.data.Data;

          // }
          // if (this.patientModel.Patient.BirthDate < "01-01-1950") {

          //   this.patientModel.Patient.BirthDate = null;
          // }

          debugger;
        }
      })
      .catch(() => {
        window.showError();
      });
  },
  computed: {
    genders() {
      return this.$store.state.GenderList.genders;
    },
     companies() {
      return this.$store.state.InsuranceCompanyList.InsuranceCompanies;
    },
  },
};
</script>
<style>
#PatientMHistory table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background-color: transparent;
}
#PatientMHistory th,
#PatientMHistory tr,
#PatientMHistory td {
  border: none;
  border-collapse: collapse;
  background-color: transparent;
}
#PatientMHistory .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#PatientMHistory .login-tabs-container {
  min-height: 505px;
}
#PatientMHistory .con-tab {
  padding-bottom: 10px;
}

#PatientMHistory .con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}

element.style {
  background: none;
}
#PatientMHistory .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}
#PatientMHistory .taskDoctor {
  background-color: transparent;
}
#PatientMHistory tr {
  height: 3.5rem;
}
#PatientMHistory .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PatientMHistory .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #PatientMHistory th,
[dir] #PatientMHistory td {
  border-bottom: 1px solid #cfcfcf !important;
}
</style>
